<template>
    <div>
        <b-card>               
            <div class="app-card-profile-title mb-3">{{trans('planning-plan-data',173)}}</div>
                               
            <b-row>
                <b-col md="6" xl="6">
                    <app-date v-model="cPlanData.date_from" :label="trans('date',182)" validatorName="Datum" :validatorRules="'required|date-check'" :validatorCustomMessage="{ 'disable-date-past': trans('sh-date-error-7',221) }" :disabledDatesPass="8"/>
                </b-col>
                <b-col md="3" xl="3">
                    <app-time v-model="cPlanData.start_time" :label="trans('planning-begin',209)" validatorName="Begin" validatorRules="required"/>
                </b-col>
                <b-col md="3" xl="3">
                    <app-time v-model="cPlanData.end_time" :label="trans('planning-end',209)" validatorName="Eind" validatorRules="required"/>                        
                </b-col>
            </b-row>

            <b-row class="mt-3" >

                <b-col md="12" xl="12">
                    <app-radio-group :label="trans('planning-service-type',209)" v-model="cPlanData.id_shift_const_daytime" validatorName="Soort dienst" validatorRules="required" :options="day_time" />
                </b-col>
            
            </b-row>

            <b-row class="mt-3">
                <b-col md="12" xl="12">
                    <app-check-box id="ch_repeat" v-model="cPlanData.repeat" class="font-weight-bold" value="1" unchecked-value="0" size="lg" :rightLabel="trans('sh-recurring-shift',219)" />
                </b-col>
            </b-row>
            
        </b-card> 

        <b-card class="mt-3" v-if="planData.repeat == 1">
            <div class="app-card-profile-title mb-3">{{trans('sh-recurring-shift',219)}}</div>
            <b-row class="mt-3" >
                    <b-col md="12" xl="12">
                        <b-form-group>
                            <b-form-checkbox-group id="checkbox-group" v-model="cPlanData.repeat_days" name="selectedDays">
                                <b-form-checkbox v-for="day in days" :key="day.value" :value="day.value" switch :disabled="cPlanData.repeat_days[0] == day.value && cPlanData.repeat_days.length == 1" >
                                    {{ day.text }}
                                </b-form-checkbox>
                            </b-form-checkbox-group>
                        </b-form-group>
                    </b-col>
            </b-row>
            <app-row-left-label :slots="[6,6]" :isMarginBetween="false" class="mt-3" v-if="planData.repeat == 1">
                <template v-slot:col-1>
                    <div class="d-flex align-items-center">
                        <b-form-radio v-model="cPlanData.repeat_type" name="some-radios" value="1" class="mt-4"/>
                        <app-input v-model="cPlanData.per_week" :label="trans('weeks-amount',3)" validatorName="Aantal weken" type="integer_02" class="w-100" :disabled="cPlanData.repeat_type != 1" :validatorRules="cPlanData.repeat_type == 1 ? 'required':''"/>
                    </div>
                </template>
                <template v-slot:col-2>
                    <div class="d-flex align-items-center">
                        <b-form-radio v-model="cPlanData.repeat_type" name="some-radios" value="2" class="mt-4"/>
                        <app-date v-model="cPlanData.date_to" :label="trans('date-to',182)" validatorName="Einddatum" :validatorRules="cPlanData.repeat_type != 2 ? '': 'required|date-check|date-more-than:' + cPlanData.date_from" class="w-100" :disabled="cPlanData.repeat_type != 2"/>
                    </div>
                </template>
            </app-row-left-label>
        </b-card>    
    </div>
</template>
<script>

import moment from "moment";
import ShiftsTypeClass from "@app/Planning/Shifts/Profile/PlanData/ShiftsTypeClass.js"
import { datecheck } from "@validations";

export default {
    components: {
    },

    props: {
        planData: {},
    },

    watch: {
        "planData.date_from": {
            handler: function(val) {
                let checkedDate = moment(this.planData.date_from, 'DD-MM-YYYY').format('YYYY,MM,DD')
                const day = new Date(checkedDate);
                const day1 = day.getDay();
                this.cPlanData.repeat_days = [];
                this.cPlanData.repeat_days.push(day1);
            }
        },

        "planData.start_time": {
            handler: function() {
                this.cPlanData.id_shift_const_daytime = this.shift_type_class.calculate(this.planData.start_time, this.planData.end_time)
            }
        },

        "planData.end_time": {
            handler: function() {
               this.cPlanData.id_shift_const_daytime = this.shift_type_class.calculate(this.planData.start_time, this.planData.end_time)
            }
        },

        "cPlanData.repeat": {
            handler: function() {
               if(this.cPlanData.repeat == 0){
                    let checkedDate = moment(this.planData.date_from, 'DD-MM-YYYY').format('YYYY,MM,DD');
                    const day = new Date(checkedDate);
                    const day1 = day.getDay();
                    this.cPlanData.repeat_days = [];
                    this.cPlanData.repeat_days.push(day1);

                    this.cPlanData.repeat_type = 1;
                    this.cPlanData.per_week = 1;
               }
            }
        },
    },

    computed: {
        cPlanData:{
            get() { return this.planData },
            set(value) { this.$emit('update:planData', value) }
        }
    },

    data() {
        return {
            repeat_type: "1",
            shift_type_class :new ShiftsTypeClass(),
            days: [
                { value: 1, text: this.trans('monday',182) },
                { value: 2, text: this.trans('tuesday',182) },
                { value: 3, text: this.trans('wednesday',182) },
                { value: 4, text: this.trans('thursday',182) },
                { value: 5, text: this.trans('friday',182) },
                { value: 6, text: this.trans('saturday',182) },
                { value: 0, text: this.trans('sunday',182) },
            ],

            day_time: [
                { value: 1, text: this.trans('day-part-morning',182) },
                { value: 2, text: this.trans('day-part-afternoon',182) },
                { value: 3, text: this.trans('day-part-day',182) },
                { value: 4, text: this.trans('day-part-evening',182) },
                { value: 5, text: this.trans('day-part-night',182) },
                { value: 6, text: this.trans('sleeping-service',182) }
            ],
        };
    },

};
</script>
