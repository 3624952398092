<template>
    <div>
        <b-row>
            <b-col cols="12">
                <app-select v-model="cPlanData.location" :label="trans('planning-hn-branch',209)" type="getUserLocations" :disable="false" validatorName="HN Vestiging" validatorRules="required" :refreshIndex.sync="refreshUserLocations" @loaded="setOffice" :setIfOne="$route.params.id_shift_data_main == null"/>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <app-select v-model="cPlanData.client_location" :label="trans('location',3)" type="getLocationsOffers" :refreshIndex.sync="refreshClientLocations" :args.sync="cPlanData.users_args" :disable="cPlanData.location == null" validatorName="Locatie" validatorRules="required"/>
            </b-col>
            <b-col cols="12"  class="d-flex w-100">
                <b-form-checkbox id="ch_afdeling" v-model="cPlanData.with_department" class="custom-control-primary pt-4 mt-1" size="lg" v-if="isImport == true" :class="cPlanData.with_department ? 'custom-checkbox-checked' : ''"/>            
                <app-select class="w-100" v-model="cPlanData.department" :label="trans('department',182)" type="getDepartments" :refreshIndex.sync="refreshDepartments" :args.sync="cPlanData.users_args" :disable="cPlanData.client_location == null || this.cPlanData.with_department == false" validatorName="Afdeling" :validatorRules="this.cPlanData.with_department != true ? 'required' : ''"/>
            </b-col>
        </b-row>   
    </div>
</template>
<script>

export default {

    props: {
        planData: {},
        loading: { type: Boolean, default: false },
        isImport: { type: Boolean, default: false },
    },

     mounted() {
        this.refreshUserLocations++;

    },

    computed: {
        cPlanData: {
            get() { return this.planData },
            set(value) { this.$emit('update:planData', value) }
        }
    },

    data() {
        return {           
            refreshDepartments: 1,
            refreshClientLocations: 1,
            refreshUserLocations: 1
        };
    },

    created() {
        

    },

    methods:{
        setOffice(event){
            if(this.$route.params.id_shift_data_main == null && this.cPlanData.location == null){
                var user_locations = JSON.parse(localStorage.getItem("USER_LOCATIONS"));
                
                if(user_locations.length == 1){
                    if( event.filter(i => i.value == user_locations[0].value).length == 1 ){

                        this.cPlanData.location = user_locations[0];
                        
                        this.cPlanData.users_args.id_office_data_location = this.cPlanData.location.value;
                    }
                }
            }
        }
    },

    watch: {
        
        "planData.location": {
            handler: function(val) {
              if (this.planData.location !== null && !this.loading) {
                    
                    this.cPlanData.users_args.id_office_data_location = this.planData.location.value;
                   
                    if (this.cPlanData.client_location  != null){
                        this.cPlanData.client_location.name = "";
                        this.cPlanData.client_location.value = null;
                        this.cPlanData.client_location = null;
                        //this.cPlanData.client_location.registration_process = 0;
                    }
                    
                    if (this.cPlanData.department  != null){
                        this.cPlanData.department.name = "";
                        this.cPlanData.department.value = null;
                        this.cPlanData.department.id_cao_data_main = null;
                        this.cPlanData.department.cao_source = null;
                        this.cPlanData.department.zzp_preferences = null;
                        this.cPlanData.department = null;
                        
                    }

                    if (this.cPlanData.function  != null){
                        this.cPlanData.function.name = "";
                        this.cPlanData.function.value = null;
                        this.cPlanData.function = null;
                        this.cPlanData.ids_function_data_main = [];
                    }

                    this.cPlanData.person = null;

                    this.refreshClientLocations++;
                
                }
            }
        },

        "planData.client_location": {
            handler: function(val) {
               
                if (this.planData.client_location !== null  && !this.loading) {
                    this.cPlanData.users_args.id_location_data_main = this.planData.client_location.value;

                    if (this.cPlanData.department  != null){
                        this.cPlanData.department.name = "";
                        this.cPlanData.department.value = null;
                        this.cPlanData.department.id_cao_data_main = null;
                        this.cPlanData.department.cao_source = null;
                        this.cPlanData.department.zzp_preferences = null;
                        this.cPlanData.department = null;
                    }

                    if (this.cPlanData.function  != null){
                        this.cPlanData.function.name = "";
                        this.cPlanData.function.value = null;
                        this.cPlanData.function = null;
                    }
                    
                    this.cPlanData.person = null;

                    this.refreshDepartments++;
                }
            }         
        },

        "planData.department": {
            handler: function(val) {
               
                if (this.planData.department !== null  && !this.loading) {

                    if (this.cPlanData.function  != null){
                        this.cPlanData.function.name = "";
                        this.cPlanData.function.value = null;
                        this.cPlanData.function = null;
                    }

                    if(this.cPlanData.department !== null && this.cPlanData.department.id_cao_data_main != null){
                        this.cPlanData.refreshFunctions++;
                    }

                    this.cPlanData.person = null;

                }
            }         
        }
    },
   
};
</script>
