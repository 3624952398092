<template>
    <div>
        <b-overlay :show="loading" :opacity="0.6">
            
            <b-card>               

                <b-alert variant="light" show class="mb-0">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="d-flex align-items-center">
                            <div>
                                <b-icon icon="check-circle-fill" font-scale="3" class="mr-4"></b-icon>                        
                            </div>

                            <div class="w-100 align-items-center">           
                                <span class="align-middle font-weight-600 text-base">{{trans('sh-created-service-function-name',215,{length : shifts.length, function_name : function_name})}}</span>  <br/>  
                                <!--<b-row>
                                    <b-col end sm="12" lg="auto">    -->
                                        <span class="app-local-person-data">
                                            <router-link class="app-local-link" :to="{name: 'clients-profile-organization', params: {'id_client_data_main': planData.client_location.id_client_data_main}}" target="_blank">{{client_location_name[0]}}</router-link> - 
                                            <router-link class="app-local-link" :to="{name: 'clients-profile-location', params: {'id_location_data_main': planData.client_location.value}}" target="_blank">{{client_location_name[1]}}</router-link> | 
                                            <router-link class="app-local-link" :to="{name: 'clients-profile-department', params: {'id_department_data_main': planData.department.value}}" target="_blank">{{planData.department.name}}</router-link></span>
                                    <!--</b-col>
                                </b-row>-->
                            </div>
                        </div>
                        <div class="d-flex align-items-center">
                            <app-button type="primary" @click="addPerson()" v-if="(user_role < 3 || can_user_edit)"> {{trans('cp-register-flexworker',189)}} </app-button>
                        </div>
                    </div>
                </b-alert> 

            </b-card> 

            <b-card no-body class="app-card-profile mt-3" v-if="shifts.length > 0">

                <b-row class="app-card-header">
                    <b-col start>
                        <div class="app-card-tittle app-local-title-height d-flex align-items-center">                       
                            <div> {{trans('services',3)}} ({{shifts.length}})</div>
                        </div>
                    </b-col>
                </b-row>
                
                
                <b-row class="ml-1 mr-1 pb-2 mt-3 app-local-row-header-font">
                    <b-col sm="1"  md="1" class="pr-0 app-local-checkbox-all">
                        <app-check-box v-model="selectedAll" value="1" unchecked-value="0" v-on:change="selectAll" />
                    </b-col>
                    <b-col sm="10" md="10">
                        <b-row>
                            <b-col cols="4">
                               {{trans('date',182)}}
                            </b-col>
                            <b-col cols="4">
                                {{trans('sh-hour',215)}}
                            </b-col>
                            <b-col cols="4">
                                {{trans('planning-service-type',209)}}
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col sm="1"  md="1" class="pr-0">
                        
                    </b-col>
                </b-row>

                <b-list-group flush class="app-local-group-list mb-2" >

                    <b-list-group-item  v-for="item in shifts" :key="item.id" class="app-local-group-item">
                                
                        <b-row align-v="center">
                            <b-col sm="1" md="1" class="pr-0">
                                <app-check-box v-model="item.selected" value="1" unchecked-value="0" v-on:change="selectedChanged(item)"/>
                            </b-col>

                            <b-col sm="10" md="10">

                                <b-row align-v="center">
                                    <b-col cols="4">
                                        {{setFormatDate(item.date_from)}}
                                    </b-col>
                                    <b-col cols="4">
                                        {{planData.start_time}} - {{planData.end_time}}
                                    </b-col>
                                    <b-col cols="4">
                                        {{getDayTime(planData.id_shift_const_daytime)}}
                                    </b-col>
                                </b-row>
                            </b-col>

                            <b-col sm="1" md="1" class="text-right">
                                
                                <b-dropdown variant="link"  no-caret right>
                                        <template #button-content>
                                            <b-icon icon="three-dots-vertical" class="text-body" ></b-icon>
                                        </template>
                                        
                                        <b-dropdown-item @click="openShift(item)">{{trans('open',175)}}</b-dropdown-item>
                                        <b-dropdown-item @click="cancelItem(item)">{{trans('cancel-service',175)}}</b-dropdown-item>

                                    </b-dropdown> 
                            </b-col>

                        </b-row>

                    </b-list-group-item>

                </b-list-group>

                <b-dropdown no-caret toggle-class="app-button-multi" variant="secondary-grey" v-if="selectedIds.length > 0">
                    <template #button-content>
                         {{trans('action',3)}} ({{selectedIds.length}})                         
                    </template>
                        
                    <b-dropdown-item @click="cancelSelectedItems()">{{trans('sh-cancel-services',220)}}</b-dropdown-item>                             
                    
                </b-dropdown>

            </b-card>
            
            <template #overlay>
                <div></div>
            </template>
        </b-overlay>    
        
    </div>
</template>
<script>
import axios from "@axios";
import moment from 'moment';
import AppAlerts from '@core/scripts/AppAlerts';
export default {
    components: {
    },

    props:["planData", "shifts"],

    watch: {
        
    },

    computed: {
        cShiftsPersonAddedCount : {
            get() { 
                    if(this.shifts != null){
                        return this.shifts.filter(({status}) => status >= 0).length;
                    }else{
                        return 0;
                    }
                },
        },

        cShifts: {
            get() {return this.shifts },
            set(value) { this.$emit('update:shifts', value) }
        }
    },

    created(){
        this.client_location_name = this.planData.client_location.name.split(' - ');
    },

    mounted() {
        this.function_name = this.shifts[0].function_name;
        this.can_user_edit = this.shifts[0].can_user_edit;
    },

    data() {
        return {
            loading: false,
            selectedAll: "0",
            selectedIds: [],
            alertClass: new AppAlerts(),
            function_name: "",
            client_location_name: null,
            can_user_edit: false,
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
        };
    },

    methods: {
        getDayTime(idShiftConstDaytime){

            if(idShiftConstDaytime == 1){
                return 'Ochtend';
            }else if(idShiftConstDaytime == 2){
                return 'Middag';
            }else if(idShiftConstDaytime == 3){
                return 'Dag';
            }else if(idShiftConstDaytime == 4){
                return 'Avond';
            }else if(idShiftConstDaytime == 5){
                return 'Nacht';
            }else if(idShiftConstDaytime == 6){
                return this.trans('sleeping-service',182);
            }

        },

        setFormatDate(dateValue) {
           return moment(dateValue).format('DD-MM-YYYY');
        },

        selectAll(){
            if(this.selectedAll == 0){
                this.shifts.forEach(element => {
                    element.selected = "0";
                    this.selectedIds.splice(this.selectedIds.indexOf(element.id), 1);
                });

            } else {
                this.selectedIds = [];
                this.shifts.forEach(element => {
                    element.selected = "1";
                    this.selectedIds.push(element.id);
                });
            }
        },

        selectedChanged(item){
            if(item.selected == "1"){
                this.selectedIds.push(item.id);
            }
            else if(item.selected == "0") {
                this.selectedIds.splice(this.selectedIds.indexOf(item.id), 1);
            }

            if(this.selectedAll == 1){
                this.shifts.forEach(element => {
                    if(element.selected == "0"){
                        this.selectedAll = 0;
                    }
                });
            }

            if(this.selectedAll == 0){
                var counter = 0;

                this.shifts.forEach(element => {
                    if(element.selected == "1"){
                        counter++;
                    }
                });

                if(counter == this.shifts.length){
                    this.selectedAll = 1;
                }
            }
        },

        openShift(item){
            let routeData = this.$router.resolve({name: 'planning-shifts-profile', params: { id_shift_data_main: item.id }});
            window.open(routeData.href, '_blank');
        },

        cancelItem(item){
            this.cancelShifts([item.id]);
        },

        cancelSelectedItems(){
            this.cancelShifts(this.selectedIds);
        },

        cancelShifts(idsShiftDataMain){

             this.alertClass.openAlertConfirmation("Weet je zeker dat je wilt annuleren?").then(res => {

                if(res == true){

                    this.loading = true;
                    axios
                        .post("planning/shifts/cancel", {
                            ids_shift_data_main: JSON.stringify(idsShiftDataMain),
                            id_shift_const_status: 17 //deleted
                        })
                        .then((res) => {
                            //
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        }).finally(() => {

                            var tmpArray = this.shifts;

                            idsShiftDataMain.forEach((item) => {

                                for(var i=0; i<tmpArray.length; i++){
                                    if(item == tmpArray[i].id){
                                        tmpArray.splice(i, 1);
                                        break;
                                    }
                                }

                            });

                            this.cShifts = tmpArray;
                            this.selectedIds = [];
                            this.selectedAll = 0;

                            this.loading = false;
                        });

                }

             });

        },

        addPerson(){
            var userRole = this.user_role;
            let ids_shift_data_main = [];

            ids_shift_data_main = this.shifts.filter(function(value) {
                                                    return ( userRole < 3 || value.can_user_edit) }).map(a => a.id);

            let dates = this.shifts.filter(function(value) {
                                    return ( userRole < 3 || value.can_user_edit) }).map(a => this.setFormatDate(a.date_from));

            var min_date = dates.reduce(function (a, b) { return moment(a, 'DD-MM-YYYY') < moment(b, 'DD-MM-YYYY') ? a : b; });
            

            this.$router.push({ name: "planning-shifts-add-person", params: { ids_shift_data_main: ids_shift_data_main, start_date: min_date }});
        }
    }

};
</script>

<style scoped>

.app-local-person-data{
    font-size: 14px;
}

.app-local-link{
    color: #174066 !important;
    font-weight: 400;
}

.app-local-link:hover{
    color: #8EC1F0 !important;
}

.app-local-link-profile{
    color: #174066 !important;
}

.app-local-link-profile:hover{
    color: #8EC1F0 !important;
}

.custom-avatar{
    height: 52px;
    width: 52px;
}

.app-local-group-list{
    border-bottom: 2px solid rgba(0, 0, 0, 0.05);
}

.app-local-group-item{
    padding: 0.5rem 1rem;
    color: #174066;
    font-weight: 300;
    font-size: 14px;
}

.app-local-group-list:hover{
    background-color: #f6f9fc;
    z-index: auto;
    text-decoration: none;
    cursor: pointer;
}

.app-local-row-header-font {
    font-weight: 600;
    color: #1f627f;
    font-size: 14px;
    text-transform: none;
    letter-spacing: 1px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.05);
}

.app-local-checkbox-all{
    padding-left: 12px;
}

.app-local-warning{
    color: #d92d20;
}

.app-local-success{
    color: #12B76A;
}

</style>
